export default function script_titleAnimation() {
  $(function(){
    $(window).scroll(function (){
      const targetArea = $('.targetArea');
  
        $(targetArea).each(function(index){
            const elemPos = $(this).offset().top,
                  scroll = $(window).scrollTop(),
                  windowHeight = $(window).height(),
                  switchPoint = 601,
                  scrollDownLine = $(this).find('.scrollDownLine__inner'),
                  title = $(this).find('.title__en--underline');
  
            if(window.innerWidth < switchPoint){   //モバイルの場合
              if(scroll > elemPos - windowHeight + 100){
                scrollDownLine.addClass('scrollDownLine__inner--animated');
                title.addClass('title__en--underline--animated');
                if(index === 3) {
                  $('.EnterPanel__blink').addClass('blink');
                }
               }
            } else {   //pcの場合
              if(scroll > elemPos - windowHeight + 300){
                scrollDownLine.addClass('scrollDownLine__inner--animated');
                title.addClass('title__en--underline--animated');
                if(index === 3) {
                  $('.EnterPanel__blink').addClass('blink');
                }
              }
            }
        });
    });
  });
}