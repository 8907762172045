export default function script_firstviewAnimation() {
  $(window).on('load', function(){     //ページ内のすべての要素が読み込まれた後に動作を行う
    setTimeout(function(){  //すべての要素が読み込まれた後0.5秒後にスタート
      $(".Load").fadeOut("slow");  //ロード幕のフェードアウト
      //$(".Load__logo").css({transform: 'scale(5) translateY(-500px)'});  //ロゴが拡大し、上部へ移動する
  
      const moveBelow = $('.FirstviewPanel__moveBelow'),  //メイン画像
            logo      = $('.FirstviewPanel__logo'),       //ロゴ
            fadeSpeed = 1000;
  
      //メイン画像が上から降ってくる（top:-50%からtop:50%）
      moveBelow.stop().animate({top: '50%'}, {duration: fadeSpeed*4, easing: 'easeOutElastic'});
  
      //ロゴがフェードイン
      logo.addClass('FirstviewPanel__fadeIn');
  
    }, 500);
  });
}
